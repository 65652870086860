import React from "react";
import ProjectList from "./ProjectList";
import "./Project.css";

const Project = () => {
  const data = [
    {
      name: "game developement",
      des: "Made in vanilla Javascript, I have learned to build small games like 2048,flappy bird, chrome dino game,snake game and mine sweeper",
      techused: [
        {
          techname: "javascript",
        },
      ],
    },

    {
      name: "Portfolio website",
      des: "A website built in react.js which is fully responsive. you can turn on/off night mode and you can download cv. It also uses react already built component like react typewriter.",
      techused: [
        {
          techname: "react js",
        },
      ],
    },

    {
      name: "Patreon clone",
      des: "Developed in next,js. In this we have implemented a patreon app in which we handled authentication and payment to the user. Along with that we have stored data of user in database along with the payment records. Also we can update the details of the user including the profile pic whenever we want",
      techused: [
        {
          techname: "Next JS",
        },
      ],
    },
    {
      name: "Food Recipe App",
      des: "Develop in javascript, This in a modern recipe app in which user get step by step directions on how to cook a dish .We also can bookmark a recipe ,change the serving ,upload the recipe In this we have implemented pagination , loading animation.",
      techused: [
        {
          techname: "Javascript",
        },
      ],
    },
    {
      name: "Chatting App",
      des: "made in node.js ,in this modern app a user can join a room to chat with other people in that room. The user joining or leaving the room is broadcasted to others",
      techused: [
        {
          techname: "Node JS",
        },
      ],
    },
  ];

  return (
    <div className="container" id="project">
      <div className="section-title">
        <h5>Projects</h5>
        <span className="line"></span>
      </div>

      <div className="row">
        {data.map((item, index) => (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" key={index}>

            <ProjectList  {...item} />
           
          </div>
        ))}
      </div>
    </div>
  );
};

export default Project;
